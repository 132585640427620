// footer {
//   display: flex;
//   background: rgba(217, 224, 236, 0.2);
//   margin-bottom: 0;
//   padding-top: 49px;
//   padding-bottom: 49px;
//   margin-top: auto;
//   padding-left: 60px;
//   padding-right: 60px;

//   .logo {
//     height: 34px;

//     +img {
//       margin-left: 8px;
//     }
//   }

//   >div,
//   .wrap-con>div {
//     display: inline-block;
//     width: auto;

//     &.wrap-left {
//       margin-right: 88px;
//     }
//   }

//   .wrap-link {
//     margin-top: 14px;
//     margin-bottom: 26px;
//   }

//   .wrap-btn-circle {
//     margin-bottom: 40px;
//     display: flex;
//     gap: 13px;

//     .btn-circle {
//       margin: 0;
//       width: 36px;
//       height: 36px;
//       background: #ccd2e3;
//     }
//   }

//   .link {
//     display: inline-block;
//     font-weight: 500;
//     font-size: 14px;
//     color: #565656;
//     padding: 0;
//     margin-right: 22px;
//   }

//   p {
//     font-size: 14px;
//     color: #565656;
//   }

//   &.pull-center {
//     padding: 0;

//     .wrap-con {
//       display: flex;
//       padding: 45px 60px 110px 60px;
//       min-width: 1140px;
//       max-width: 1280px;
//       flex-grow: 1;
//       margin: 0 auto;
//     }
//   }
// }

// // @media (max-width: 991.98px)
// @include media-breakpoint-down(md) {
//   footer {
//     flex-direction: column;
//     padding: 45px 20px 95px 20px;

//     .logo {
//       height: 40px;
//     }

//     >div.wrap-left {
//       margin-bottom: 20px;
//       margin-right: 0;
//     }

//     p {
//       font-size: 12px;
//       line-height: 160%;
//     }

//     .link {
//       margin-right: 20px;
//     }

//     .wrap-btn-circle {
//       margin-bottom: 20px;
//     }
//   }
// }

//footer
.line {
  width: 100%;
  height: 1px;
  background: var(--c-border-line);
}
.right-40 {
  margin-right: 40px;
}
.footer {
  grid-area: footer;
  background-color: var(--bg-main);
  .line-first {
    margin-bottom: 36px;
  }
  .wrapper-footer {
    display: flex;
    justify-content: space-between;
    margin: auto;
    max-width: 1416px;
    gap: 64px;
    margin: 0px auto;
    padding: 0 40px;
    .navbar-footer {
      grid-area: navbar-footer;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 120px;
      // margin-right: 64px;

      .list-nav {
        display: flex;
        flex-direction: column;
        border-bottom: unset;
        a {
          text-decoration: none;
          color: var(--c-talken-studio);

        }
        .nav-item-name {
          margin-top: 0;
          font-size: 16px;
          display: block;
          margin-block-start: 1em;
          margin-block-end: 1em;
          margin-inline-start: 0px;
          margin-inline-end: 0px;
          // font-weight: bold;
          font-weight: 700;
        }

        .nav-item {
          text-decoration: none;
          color: var(--c-navlink-footer);
          margin-bottom: 12px;
          font-size: 14px;
          text-align: left;
          transition: color 0.15s ease 0s;
          &:hover {
            color: var(--text-primary);
          }
        }
      }
    }

    .logo-footer {
      grid-area: logo-footer;
      // width: 180px;

      img {
        // width: 100%;
      }
    }

    .list-page {
      grid-area: list-page;
      .content-head {
        font-size: 14px;
        color: var(--c-navlink-footer);
        padding: 10px 0;
        .content {
          padding-bottom: 10px;
        }
        .content-01 {
          font-weight: 600;
        }
      }
      // .social-networks {
      //   display: flex;
      //   // justify-content: space-between;
      //   gap: 20px;

      //   .social-item {
      //     width: 30px;
      //     opacity: 0.6;
      //     transition: color 0.15s ease 0s;
      //     &:hover {
      //       opacity: 1;
      //     }

      //     img {
      //       width: 100%;
      //     }
      //   }
      // }
      .footLink {
        // padding: 10px 0 0 0;
        font-size: 0;
        a {
          display: inline-block;
          width: 46px;
          height: 46px;
          background: url('~/assets/icon/footerlink.png') no-repeat 0
            10px;
          background-size: auto 23px;
          text-decoration: none;
          transition: color 0.15s ease 0s, background-color 0.15s ease 0s;
        }
        .footLink3 {
          background-position-x: -94px;
        }
        .footLink2 {
          background-position-x: -40px;
        }
        .footLink5 {
          background-position-x: -179px;
        }
        .footLink6 {
          background-position-x: -224px;
        }
        .footLink1 {
          width: 45px;
          background-position-x: 5px;
        }
        .footLink7 {
          background-position-x: -265px;
        }
      }
    }
  }
  .line-second {
    height: 1px;
    max-width: 1336px;
    margin: 15px auto 22px;
  }
  .footer-bottom {
    display: flex;
    justify-content: space-between;
    color: var(--c-navlink-footer);
    max-width: 1416px;
    margin: auto;
    font-size: 14px;
    // padding-bottom: 28px;
    padding: 0 40px 76px;
    .footer-bottom-left {
      display: flex;
      align-items: center;
      gap: 30px;
    }
    .contact-us {
      color: var(--c-navlink-footer);
      display: flex;
      justify-content: space-between;
      gap: 10px;
      transition: color 0.15s ease 0s;
      .icon-contact {
        width: 16px;
        height: 16px;
        opacity: 0.6;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
        }
        // &:hover {
        //   opacity: 1;
        // }
      }
      &:hover {
        color: var(--text-primary);
        .icon-contact {
          opacity: 1;
        }
      }
    }
  }
  @media (max-width: 1200px) {
    .wrapper-footer {
      .navbar-footer {
        grid-gap: 64px;
        display: grid;
        // grid-template-columns: 1fr 1fr;
        margin-right: 32px;
      }
    }
  }
  @media (max-width: 1000px) {
    // padding: 0 40px;
    .wrapper-footer {
      flex-direction: column-reverse;
      gap: 60px;
      .navbar-footer {
        grid-gap: 32px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        margin-right: 0;
      }
      .footer-right {
        .list-page {
          .social-networks {
            gap: 20px;
            justify-content: flex-start;
          }
        }
      }
    }
  }
  @media (max-width: 540px) {
    padding: 0 20px;
    .wrapper-footer {
      padding: 0;
      .navbar-footer {
        grid-template-columns: 1fr 1fr;
        grid-gap: 32px 16px;
      }
      .footer-right {
        .content-head {
          font-size: 14px;
        }
      }
    }
    .footer-bottom {
      flex-direction: column-reverse;
      padding: 0;
      padding-bottom: 32px;
      .contact-us {
        margin-bottom: 16px;
        justify-content: flex-start;
      }
    }
  }
  @media (max-width: 330px) {
    .wrapper-footer {
      .footer-right {
        .list-page {
          .social-networks {
            gap: 18px;
          }
        }
      }
    }
  }
}
