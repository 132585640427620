.modal-backdrop.show {
  opacity: 0.8;
}

.modal {
  .modal-dialog {
    max-width: 580px;
    justify-content: center;
  }

  &:not(.pop-admin) .dropdown-toggle::after {
    background-image: url(../img/i_arr_round_g.svg);
    width: 22px;
    height: 22px;
  }

  .wrap-step {
    padding-top: 3px;
  }

  .list-group {
    margin: 6px 0 0;

    .btn-dwl.position-absolute {
      top: calc(50% - 15px);
      right: 20px;
      background-color: #7d8490;
      width: 30px;
      height: 30px;
      @include i-bg;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 20 20'%3E%3Cpath fill='%23F7F9FC' d='M16.584 12.5a.75.75 0 0 0-1.5 0h1.5Zm-11.667 0a.75.75 0 0 0-1.5 0h1.5Zm5.833-8.334a.75.75 0 0 0-1.5 0h1.5ZM10 12.5l-.53.53a.75.75 0 0 0 1.06 0L10 12.5Zm-1.136-2.197a.75.75 0 1 0-1.06 1.06l1.06-1.06Zm3.333 1.06a.75.75 0 0 0-1.06-1.06l1.06 1.06Zm1.97 3.72H5.834v1.5h8.333v-1.5Zm2.417-.917V12.5h-1.5v1.666h1.5Zm-11.667 0V12.5h-1.5v1.666h1.5Zm.917.917a.917.917 0 0 1-.917-.917h-1.5a2.417 2.417 0 0 0 2.417 2.417v-1.5Zm8.333 1.5a2.417 2.417 0 0 0 2.417-2.416h-1.5c0 .506-.41.916-.917.916v1.5ZM9.25 4.167V12.5h1.5V4.166h-1.5Zm1.28 7.803-1.666-1.667-1.06 1.06L9.47 13.03l1.06-1.06Zm0 1.06 1.667-1.666-1.06-1.061-1.667 1.666 1.06 1.061Z'/%3E%3C/svg%3E%0A");
      background-size: 20px 20px;

      &:hover {
        background-color: $c-main;
      }
    }

    .btn-radio {
      .wrap-img {
        width: 40px;
        height: 40px;
        background: #fff;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          max-width: 30px;
          max-height: 30px;
          width: auto;
        }
      }
    }
  }

  .wrap-btn {
    margin: 0;
    display: flex;
    gap: 10px;
  }

  .list-group-checkable {
    margin: 16px 0;
  }

  .txt-a.step {
    font-weight: 500;
    font-size: 12px;
  }

  h3.sub {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-primary);
    margin-bottom: 6px;

    &.txt-a {
      color: #fd1717;
    }
  }

  h4 {
    font-size: 16px;
    line-height: 24px;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #464f60;

    &.txt-a {
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      color: #ff2f00;
    }
  }

  &.modal-sm {
    max-width: 100%;

    .modal-content {
      max-width: 437px;
    }

    .modal-header {
      padding-top: 30px;
    }

    .modal-footer {
      padding-bottom: 20px;
    }
  }

  .form-control {
    padding: 13px 29px;
    font-size: 14px;
    height: 38px;
    background: rgba(217, 224, 236, 0.2);
    border-radius: 100px;
    border: 0;
  }

  .input-group {
    >* {
      border: 0;
      background: #f7f8fb;
      font-weight: 500;
      font-size: 16px;
      color: #2c2c2c;
    }

    .input-group-text {
      padding-left: 0;
      padding-right: 0;
    }

    .btn {
      font-weight: 500;
      font-size: 14px;
      color: rgba(70, 79, 96, 0.6);
      padding-top: 13px;
      padding-bottom: 13px;
      height: 38px;
    }

    // &.input-group-time {
    // }
  }

  .modal-content {
    background: var(--background-modal);
    // box-shadow: 4px 4px 4px rgba(183, 183, 183, 0.25);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 24px;
    border: 0;
    max-width: 506px;
  }

  .modal-header {
    position: relative;
    padding: 24px 36px 16px 36px;
    border: 0;
    justify-content: center;
    color: var(--text-primary);
    .btn-close {
      position: absolute;
      top: 26px;
      right: 26px;
      background-image: url(../img/i_x_pop.svg);
      width: 23px;
      height: 23px;
      padding: 0;
      background-size: 100%;
      opacity: 1;
      margin: 0;
    }

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 1.5;
      color: #8d8d8d;
    }

    .modal-header-icon {
      margin: 0 auto;
      margin-top: -30px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .modal-title-icon {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        border-radius: 100px;

        &.i-warning {
          background: #fd1717;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='28' fill='none' viewBox='0 0 6 28'%3E%3Cpath fill='%23fff' d='M3.385.322h-.587A2.615 2.615 0 0 0 .183 2.937v12.5a2.615 2.615 0 0 0 2.615 2.615h.587A2.615 2.615 0 0 0 6 15.437v-12.5A2.615 2.615 0 0 0 3.385.322Zm-.293 27.643a2.908 2.908 0 1 0 0-5.817 2.908 2.908 0 0 0 0 5.817Z'/%3E%3C/svg%3E%0A");
          margin-bottom: 8px;
          border: 7px solid #fff;
          box-sizing: content-box;
          @include i-bg;
          background-size: 6px auto;
        }
      }

      p.sm {
        font-size: 12px;
        color: #464f60;
        margin: 8px 0 0;
      }
    }
  }

  .modal-title {
    font-weight: 600;
    font-size: 20px;
  }

  .modal-body {
    padding: 0 36px 0 36px;

    &:last-child {
      padding-bottom: 36px;
    }

    .box-fill {
      border-radius: 15px;
      font-weight: 500;
      font-size: 22px;
      padding-left: 28px;
      background: #f4f5f8;
      gap: 16px;
      border: 3px solid #f4f5f8;
      display: flex;
      align-items: center;
      justify-content: start;

      p {
        font-weight: 400;
        font-size: 10px;
        line-height: 1.5;
        color: #464f60;
        margin: 0;
      }
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 1.5;
      color: var(--text-secondary);
      margin-bottom: 24px;
      text-align: center;
      padding-top: 10px;

      &.sm {
        font-size: 12px;
      }
    }

    .link {
      &.support {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        // color: #030229;
        color: #464f60;
        display: flex;
        display: flex;
        align-items: center;

        &:before {
          background-image: url(../img/i_chat.svg);
          width: 20px;
          height: 20px;
          margin-right: 6px;
          @include i-bg;
        }
      }
    }

    .wrap-nft {
      display: flex;
      margin-top: 40px;
      gap: 20px;

      .thumb {
        width: 160px;
        height: 160px;
        justify-content: center;

        img,
        video {
          border-radius: 14px;
        }
      }

      .wrap-txt {
        padding-top: 12px;

        .sub {
          font-weight: 400;
          font-size: 14px;
          margin-bottom: 0;
        }

        .title {
          font-weight: 600;
          font-size: 24px;
        }

        .wrap-btn-circle {
          margin-top: 16px;
          display: flex;
          gap: 6px;

          .btn-circle {
            width: auto;
            height: auto;

            .i {
              width: 28px;
              height: 28px;
            }
          }
        }

        .price {
          margin-top: 18px;

          .klay {
            font-weight: 600;
            font-size: 26px;

            &:before {
              width: 23px;
              height: 23px;
              margin-right: 5px;
            }

            span {
              font-weight: 400;
              font-size: 13px;
              color: rgba(70, 79, 96, 0.5);
            }
          }
        }
      }
    }

    .box {
      gap: 14px;
      padding: 0;
      background: transparent;
      display: flex;
      align-items: center;

      &.box-line {
        .wrap-title {
          width: 210px;
        }
      }

      span {
        font-size: 14px;
        color: #464f60;
      }

      .thumb {
        width: 63px;
        height: 63px;
        border-radius: 14px;
      }

      .wrap-title {
        flex: 1 0 auto;
        // width: 272px;
      }

      .sub {
        font-weight: 500;
        font-size: 14px;
        color: #030229;
        margin-bottom: 0;
        @include t-ell;
      }

      .title {
        font-weight: 600;
        font-size: 16px;
        line-height: 27px;
        color: #030229;
        margin-bottom: 0;
        @include t-ell2;
      }

      .klay {
        font-size: 16px;
        font-weight: 600;
        color: #030229;

        &:before {
          width: 24px;
          height: 24px;
        }

        &.klay-lg {
          font-size: 20px;

          &:before {
            margin-right: 8px;
            width: 20px;
            height: 20px;
          }
        }
      }

      &.box-fill {
        background: $c-g6;
        border: 0;
        border-radius: 16px;
        padding: 20px 24px;
        display: flex;
        width: 100%;
        overflow: hidden;

        &.h-fixed {
          height: 120px;
        }

        >div {
          flex: 0 0 auto;

          &.wrap-thumb .thumb {
            width: 72px;
            height: 72px;
          }

          &.wrap-title {
            max-width: calc(100% - 200px);
          }
        }

        h4.title {
          margin-bottom: 4px;
          // word-break: break-all;
          line-height: 1.5;
          font-weight: 500;
        }

        h6 {
          font-size: 13px;
          margin-bottom: 4px;
        }

        p {
          font-size: 13px;

          &.sm,
          &.sm span {
            font-size: 11px;
          }
        }

        .wrap-price {
          display: flex;
          flex-direction: column;
          margin-left: auto;
          text-align: right;
          justify-content: flex-end;

          .klay {
            justify-content: flex-end;
          }

          .klay::before {
            width: 20px;
            height: 20px;
          }
        }

        &.align-center {
          justify-content: center;

          .token {
            display: flex;
            align-items: center;
            gap: 8px;

            .i-token {
              width: 24px;
            }

            h6 {
              font-weight: 600;
              color: #030229;
              margin: 0;
            }
          }
        }
      }

      &.box-line {
        gap: 20px;
        padding: 12px 0;
        border-top: 0.8px solid #9ba4bb;
        border-bottom: 0.8px solid #9ba4bb;
        margin: 6px 0 30px 0;

        .thumb {
          width: 53px;
          height: 53px;
        }

        .sub {
          font-weight: 400;
          font-size: 14px;
          // line-height: 1;
          margin-bottom: 5px;
        }

        .title {
          font-weight: 600;
          font-size: 18px;
          // line-height: 1;
        }

        .price {
          text-align: right;
          display: flex;
          flex-direction: column;

          span {
            font-weight: 400;
            font-size: 10px;
          }

          .klay {
            font-weight: 600;
            font-size: 18px;
            max-width: 115px;
            overflow: hidden;
            @include t-ell;

            &:before {
              width: 19px;
              height: 19px;
              margin-right: 4px;
            }
          }

          // .wklay {}
        }
      }
    }
  }

  .wrap-form {
    display: block;
    margin-bottom: 30px;

    .wrap-title.d-flex {
      margin-bottom: 0;
      align-items: center;

      .title {
        margin-bottom: 0;
      }

      +span.time {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #464f60;
        // margin-top: -20px;
      }
    }

    .title {
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 12px;
      color: #2c2c2c;
      display: flex;
      align-items: baseline;

      // >.pull-right {}
    }

    .form {
      display: flex;
      padding: 0;
      margin: 20px 0 15px 0;
      gap: 8px;

      label {
        height: 38px;
        background: rgba(217, 224, 236, 0.4);
        border-radius: 100px;
        padding: 0 15px;
        margin-right: 10px;
        font-size: 14px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
    }

    .alert {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #fd1717;
      padding: 12px 0 0;
      margin: 0;
      display: flex;
      gap: 5px;
      align-items: flex-start;

      img {
        margin-top: 2px;
      }
    }

    .balance {
      font-weight: 500;
      font-size: 12px;
      color: #464f60;
      float: right;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .wrap-price label {
      width: 108px;
      flex: 1 0 auto;
      margin-right: 0;
    }

    &.wrap-exp {
      margin-bottom: 25px;

      label {
        width: 50px;
        flex: 1 0 50px;
      }
    }
  }

  .wrap-price {
    position: relative;

    .form-control {
      padding-right: 90px;
    }

    span.unit {
      position: absolute;
      top: 9px;
      right: 26px;
      font-size: 14px;
      color: rgba(70, 79, 96, 0.6);
    }
  }

  .wrap-whitelist {
    .li-item {
      display: flex;
      margin-bottom: 12px;
      padding-bottom: 12px;
      border-bottom: 1px solid #ccd2e3;

      &:first-child {
        padding-top: 6px;
      }

      .wrap-con {
        max-width: calc(100% - 150px);

        h5 {
          font-weight: 400;
          word-break: break-all;
          margin: 0;
        }
      }

      .wrap-btn {
        gap: 0;
        margin-left: auto;

        .btn {
          width: 40px;
          height: 40px;
        }
      }
    }
  }

  .box-qr {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    .logo-clip {
      width: 36px;
    }

    .wrap-img {
      img {
        width: 160px;
        height: 160px;
      }
    }

    .wrap-txt {
      margin-top: 6px;

      p,
      time {
        font-size: 13px;
        margin-bottom: 6px;
        color: #030229;
      }

      .wrap-time {
        display: flex;
        justify-content: center;
        gap: 6px;

        p {
          color: #7d8490;
        }

        .time {
          color: #ff2f00;
        }
      }
    }
  }

  .modal-footer {
    // padding: 0 43px 43px 43px;
    padding: 18px 36px 36px 36px;
    border: 0;
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;

    .wrap-btn {
      width: 100%;
      justify-content: center;
    }

    .btn {
      font-weight: 600;
      font-size: 16px;
      min-width: 156px;
      height: 44px;
      width: 100%;

      &.btn-a-line {
        border: 1.5px solid #2f80ed;
      }

      &.btn-connect {
        height: 66px;
        color: #030229;
        font-weight: 500;
        background: rgba(217, 224, 236, 0.2);
        border-radius: 16px;
      }
    }

    .wrap-btn+.sm {
      display: flex;
      margin-top: 12px;
      margin-bottom: -10px;
      gap: 4px;
      align-items: center;

      p {
        font-weight: 500;
        font-size: 10px;
        line-height: 15px;
        color: #626978;
        margin: 0;
      }
    }

    .indicators {
      margin-top: 15px;
      gap: 10px;
      display: flex;
      align-items: center;

      span {
        width: 11px;
        height: 11px;
        text-indent: -999px;
        border-radius: 11px;
        background: #c4c4c4;

        &.active {
          background: #ff2f00;
        }
      }
    }
  }

  .wrap-form .form {
    margin: 0;
  }

  .wrap-btm {
    text-align: center;
    margin-top: 15px;

    .box {
      height: 57px;
      background: #f7f9fc;
      border-radius: 10px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #5e5e5e;
      padding: 16px;
      display: block;
      @include t-ell;
    }

    .wrap-price {
      .form-control {
        padding-right: 29px;
      }

      label {
        font-weight: 600;
        font-size: 18px;
        color: #2c2c2c;
        background: rgba(217, 224, 236, 0.5);
      }
    }
  }

  .wrap-box {
    padding: 10px 0;
    border-top: 0.5px solid #d4d4d4;
    border-bottom: 0.5px solid #d4d4d4;

    .box {
      background: transparent;
      border-radius: 0;
      padding: 0;
      height: 40px;

      .klay:before {
        width: 20px;
        height: 20px;
        margin-right: 12px;
      }

      .price {
        align-items: baseline;
        min-width: 100px;
        font-weight: 600;
        font-size: 20px;
        text-align: right;
        color: #2c2c2c;
        justify-content: flex-end;
        display: flex;
        align-items: center;

        &.active {
          color: #ff2f00;
        }

        span {
          font-weight: 500;
          font-size: 20px;
          color: #2c2c2c;
          margin-left: 4px;
        }
      }
    }

    .wrap-label {
      flex: 1 0 auto;
      text-align: left;
      font-weight: 500;
      font-size: 16px;

      span {
        color: #2c2c2c;
      }
    }
  }

  &.pop-after-x {
    &:after {
      content: "";
      width: 40px;
      height: 40px;
      position: absolute;
      top: 21px;
      right: 21px;
      background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%239BA4BB'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
      @include i-bg;
      background-size: 16px 16px;
      cursor: pointer;
      opacity: 0.8;
    }
  }

  &.pop-img {
    // position: relative;
    padding: 40px;
    padding-top: calc(40px - 1.75rem);
    padding-bottom: calc(40px - 1.75rem);

    .modal-dialog {
      max-width: 100vw;
    }

    .modal-content {
      min-width: 0;
      min-height: 0;
      max-width: 100%;
      width: fit-content;
      height: fit-content;
      background-color: hsl(0, 0%, 90%);
      overflow: hidden;
      border-radius: 0;

      img,
      video {
        max-width: calc(100vw - 7rem);
        max-height: calc(100vh - 7rem);
      }
    }

    .btn-close {
      position: absolute;
      top: 40px;
      right: 40px;
      background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%239BA4BB'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
      z-index: 1000;
    }
  }

  &.pop-xl {
    .modal-content {
      min-width: 812px;
      min-height: 545px;
    }
  }

  &.pop-lg {
    .modal-content {
      min-width: 504px;
      min-height: 545px;
    }
  }

  &.pop-md {
    .modal-content {
      min-width: 504px;
      min-height: 385px;
    }
  }

  &.pop-sm {
    .modal-content {
      min-width: 504px;
      min-height: 265px;
    }
  }

  &.pop-loading {
    .modal-body {
      text-align: center;

      img.loading {
        width: 68px;
        margin-top: 14px;
      }
    }
  }

  &.pop-deposit {
    .wrap-btm {
      margin-top: 25px;
    }
  }

  &.pop-step-balance {
    .wrap-box {
      margin-top: 70px;
    }
  }

  &.pop-bid {
    .wrap-form {
      margin-bottom: 12px;
    }
  }

  .dropdown:not(.dropdown-line) {
    .btn {
      height: 38px;
      background: rgba(217, 224, 236, 0.2);
      font-size: 14px;
      font-weight: 500;
      padding-right: 12px;
    }

    .i {
      max-height: 16px;
      // margin-right: 5px;
    }

    time {
      min-width: 80px;
      text-align: left;
    }

    // .dropdown-menu {
    //   // border-radius: 25px;
    // }
  }

  &.pop-admin .dropdown:not(.dropdown-line) .btn {
    background: transparent;
  }

  .dropdown-line {
    width: 100%;
    text-align: left;
    margin-top: 12px;

    .btn {
      font-size: 14px;
      font-weight: 500;
      width: 100%;
      height: 45px;
      border: 1px solid #cbcbcb;
      max-width: none;
    }

    ul {
      width: 100%;
    }
  }
}
// popup connect wallet modal
.popup-content {
  margin: auto;
  background: transparent;
  // width: 600px;
  width: 376px;
  padding: 0px;
  border: none;
  display: flex;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Poppins,
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

  .login_form {
    animation: 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards fade-in;
    z-index: 30;
    // background-color: #23235c;
    background-color: #fff;
    color: #1f1f1f;
    border: none;
    outline: none;
    margin: auto;
    border-radius: 30px;
    height: 470px;
    width: 600px;

    .box-content {
      position: relative;
      height: 100%;
      text-align: center;
      .connect-wallet-line {
        width: 100%;
        height: 1px;
        background: #d9d9d9;
        opacity: 0.5;
      }
      input {
        border: 1px solid white;
        border-radius: 10px;
        padding: 10px 10px 14px;
        outline: none;
        font-size: 18px;
        background: transparent;
        color: white;
      }

      button {
        // font-family: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI',
        //   'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        //   'Helvetica Neue', sans-serif;
        border: none;
        cursor: pointer;
      }

      .div-title {
        // font-family: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI',
        //   'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        //   'Helvetica Neue', sans-serif;
        // display: flex;
        // -webkit-box-pack: justify;
        // justify-content: space-between;
        // margin-bottom: 25px;
        // height: 44px;
        // -webkit-box-align: center;
        // align-items: center;
        // padding-top: 5%;
        // padding-right: 10%;
        display: flex;
        justify-content: flex-end;
        height: 80px;
        align-items: center;
        padding-right: 30px;

        .title {
          // font-family: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI',
          //   'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
          //   'Helvetica Neue', sans-serif;
          // font-size: 24px;
          // font-weight: 600;
          // flex: 1 1 0%;
          // padding-left: 20%;
          font-size: 24px;
          font-weight: 600;
          flex: 1 1;
          padding-left: 20%;
        }
      }
      .div-methods {
        color: white;
        font-size: 15px;
      }

      .methods {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        width: 60%;
        margin: 35px auto 15px;
        -webkit-box-pack: justify;
        justify-content: space-between;

        button {
          cursor: pointer;
          width: 50px;
          height: 50px;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          border-radius: 4px;
        }

        .method-apple {
          background-color: black;
        }

        .method-twitter {
          background-color: #54b0e1;
        }

        .method-google {
          background-color: white;
        }
      }

      .text_switch {
        // font-family: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI',
        //   'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        //   'Helvetica Neue', sans-serif;
        height: 50px;
        text-align: center;
        margin: 10px auto 0px;
        width: 100%;
        margin-top: 5%;

        button {
          // font-family: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI',
          //   'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
          //   'Helvetica Neue', sans-serif;
          font-size: 15px;
          font-weight: 600;
          color: white;
          border: none;
          background-color: transparent;
        }
      }
      .box-input {
        padding: 27px 30px 0;
        .div-mobile {
          color: white;
          font-size: 15px;
          margin-top: 5%;
        }

        form {
          .phone_number {
            margin-top: 5%;
            font-size: 18px;
            border: 1px solid white;
            border-radius: 10px;
            padding: 3px 10px;

            .react-tel-input {
              // font-family: Poppins, -apple-system, BlinkMacSystemFont,
              //   'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
              //   'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
              font-size: 15px;
              position: relative;
              width: 100%;

              .country-list {
                .flag {
                  transform: scale(1.5);
                }

                .country {
                  &:hover {
                    background-color: #ffffff40;
                  }
                }

                .country.highlight {
                  background-color: #ffffff40;
                }
              }

              .selected-flag {
                &:hover {
                  background-color: transparent;
                }

                &:focus {
                  background-color: transparent;
                }

                .flag {
                  transform: scale(1.5);
                }
              }

              .flag-dropdown.open {
                .selected-flag {
                  background: transparent;
                  border-radius: 3px 0 0 0;
                }
              }
            }

            .react-tel-input {
              > input.form-control {
                width: 100%;
                border: none;
                padding: 0px 0px 0px 35px;
                font-size: 16px;
                background-color: transparent;
                margin-left: 4%;
              }
            }
          }
        }

        .button-forgot-password {
          // font-family: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI',
          //   'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
          //   'Helvetica Neue', sans-serif;
          font-weight: 100;
          font-size: 14px;
          margin-top: 10px;
          color: white;
          background-color: transparent;
        }

        .div-keep_logged_in {
          margin-top: 20px;
          display: flex;
          -webkit-box-align: center;
          align-items: center;

          input {
            border: 1px solid white;
            border-radius: 10px;
            padding: 10px 10px 14px;
            outline: none;
            font-size: 18px;
            background: transparent;
            color: white;
          }

          span {
            margin-left: 15px;
          }
        }
        .box-top {
          width: 100%;
          height: 94px;
          background-color: #f0f5ff;
          display: flex;
          align-items: center;
          border-radius: 10px;
          .div-wallets_1 {
            background-color: transparent;
            height: 62px;
            overflow: hidden;
            display: flex;
            justify-content: space-around;
            gap: 8px;
            padding: 0 16px;
            width: 100%;
            button {
              font-size: 14px;
              font-family: 'Roboto', -apple-system, BlinkMacSystemFont,
                'Segoe UI', Poppins, 'Oxygen', 'Ubuntu', 'Cantarell',
                'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
              color: rgb(65, 65, 65);
              width: 100%;
              height: 100%;
              border-radius: 7px;
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 8px;
              background-color: rgba(121, 121, 121, 0.18);
              color: white;
              &.active {
                background-color: #bae7ff;
                //border: 2px solid #2f8af5;
                //color: rgba(121, 121, 121, 0.8);;
                color: white;
              }

              &.unused {
                background-color: rgba(121, 121, 121, 0.18);
                color: white;
                cursor: default;
              }
              &.focused {
                //background-color: rgba(121, 121, 121, 0.18);
                background-color: white;
                //border: 2px solid #2f8af5;
                color: rgba(121, 121, 121, 0.8);
              }
              .custom-image {
                width: 24px;
                height: 24px;
                img {
                  width: 100%;
                }
              }

              // &:not(:last-child) {
              //   margin-right: 2%;
              // }
            }
          }
        }
        .div-wallets_2 {
          width: 100%;
          height: 235px;

          background-color: #f0f5ff;
          border-radius: 10px;
          margin: 16px 0px 33px;
          .row-wallet {
            background-color: transparent;
            width: 100%;
            overflow: hidden;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-row: auto;
            gap: 8px;
            padding: 16px;

            &:not(:last-child) {
              margin-bottom: 3%;
            }

            button {
              font-size: 14px;
              text-align: left;
              font-family: 'Roboto', -apple-system, BlinkMacSystemFont,
                'Segoe UI', Poppins, 'Oxygen', 'Ubuntu', 'Cantarell',
                'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
              color: rgb(0, 0, 0);
              width: 100%;
              height: 62px;
              border-radius: 4px;
              display: flex;
              align-items: center;
              gap: 8px;
              padding-left: 20px;
              background: #ffffff;
              // &:not(:last-child) {
              //   margin-right: 2%;
              // }

              &.active {
                background-color: #bae7ff;
                border: 2px solid #2f8af5;
              }
              .custom-image {
                width: 24px;
                height: 24px;
                img {
                  width: 100%;
                }
              }
            }
          }
          .error-message {
            width: 100%;
            //border: 1px solid red;
            margin-top: -10px;
            color: red;
          }
        }

        .animated {
          animation: top_to_down 3s;

          .reCaptcha {
            margin-top: 8%;
            margin-bottom: 8%;
          }
        }

        .button-login {
          background: linear-gradient(
            to left,
            rgb(103, 129, 249),
            rgb(227, 100, 190)
          );
          // font-family: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI',
          //   'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
          //   'Helvetica Neue', sans-serif;
          width: 100%;
          margin-top: 25px;
          border-radius: 40px;
          outline: none;
          font-size: 22px;
          height: 70px;
          color: white;
        }
      }

      .button-close {
        background-color: transparent;
        padding: 0;
      }
    }
  }
  @media (max-width: 600px) {
    .login_form {
      border-radius: 0;
      height: 100vh;
      width: 100vw;

      .box-content {
        .div-title {
          .title {
            font-size: 22px;
          }
        }
        form {
          .box-input {
            padding: 27px 10px 0;
            .box-top {
              .div-wallets_1 {
                padding: 0 10px;
                button {
                  font-size: 12px;
                  flex-direction: column;
                }
              }
            }

            .div-wallets_2 {
              .row-wallet {
                button {
                  // font-size: 11.7px;
                  flex-direction: column;
                  justify-content: center;
                  padding-left: 0;
                }
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 340px) {
    .login_form {
      .box-content {
        form {
          .box-input {
            .box-top {
              .div-wallets_1 {
                button {
                  font-size: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
  // @include Mobile() {
  //   .login_form {
  //     border-radius: 0;
  //     height: 100vh;
  //     width: 100vw;

  //     .box-content {
  //       form {
  //         .box-input {
  //           .div-wallets_1 {
  //             button {
  //               font-size: 9px;
  //             }
  //           }

  //           .div-wallets_2 {
  //             .row-wallet {
  //               button {
  //                 font-size: 11.7px;
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
}

// @media (max-width: 767.98px), mobile only
@include media-breakpoint-down(sm) {
  .modal.pop-img {
    padding: calc(40px - 1.75rem);

    // padding: 10px;
    .modal-content {
      border-radius: 0;
    }

    .btn-close {
      top: 21px;
      right: 21px;
    }
  }
}

// @media (max-width: 991.98px)
@include media-breakpoint-down(md) {
  .modal {
    &.m-pop-center {
      .modal-content {
        min-width: 0;
        min-height: 0;
        width: calc(100vw - 40px);

        .modal-header {
          padding: 0 20px 12px 20px;
        }

        .modal-body {
          padding: 0 20px 20px;
        }
      }
    }

    .dropdown-menu li button {
      font-size: 16px;
    }

    &:not(.pop-img, .m-pop-center) .modal-content {
      background: #fefeff;
      min-width: 0 !important;
      width: 100vw;
      border-radius: 30px 30px 0 0;
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .modal-body {
      padding: 0 26px;
      padding: 0 26px 20px 26px;

      .box-fill {
        border-radius: 8px;
        padding: 8px 18px;
      }

      p {
        margin-bottom: 16px;
      }

      .wrap-nft {
        margin-top: 60px;

        .thumb {
          width: 137px;
          height: 137px;
        }

        .wrap-txt {
          .sub {
            font-size: 14px;
          }

          .title {
            font-size: 22px;
          }

          .btn-circle img {
            width: 32px;
            height: 32px;
          }

          .price {
            .klay {
              font-size: 22px;

              &:before {
                width: 22px;
                height: 22px;
                margin-right: 6px;
              }

              span {
                flex: 1 0 auto;
                width: 100%;
                margin-left: 0;
                font-size: 14px;
              }
            }
          }
        }
      }

      .box {
        &.box-fill {}

        &.box-line {
          .title {
            line-height: 24px;
          }

          .wrap-title {
            width: 140px;
          }
        }

        .title {
          font-size: 14px;
          line-height: 14px;
        }

        span {
          font-size: 12px;
          line-height: 12px;
        }

        .klay.klay-lg {
          font-size: 18px;

          &:before {
            width: 18px;
            height: 18px;
            margin-right: 6px;
          }
        }
      }

      .box.box-line {
        margin-bottom: 22px;
        margin-top: 6px;
        gap: 9.5px;

        .thumb {
          width: 47.5px;
          height: 47.5px;
        }

        .price {
          span {
            font-size: 12px;
            margin: 8px 0 4px 0;
          }

          .klay {
            font-size: 16px;

            &:before {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }

    .wrap-btm {
      margin-top: 16px;

      .box {
        height: 43px;
        padding: 10px 26px;
      }
    }

    .wrap-box {
      .wrap-label {
        font-size: 12px;
      }

      .box {
        height: 28px;

        .price,
        .price span {
          font-size: 16px;
        }

        .klay:before {
          width: 18px;
          height: 18px;
          margin-right: 6px;
        }
      }
    }

    .dropdown-line {
      button {
        font-size: 14px;
      }
    }

    .wrap-form {
      &.wrap-exp {
        .form {
          flex-direction: column;

          .dropdown .btn,
          ul.dropdown-menu {
            width: 100%;
          }
        }
      }

      .balance {
        font-size: 11px;
      }
    }

    .form-control {
      padding: 9px 15px;
    }

    // .wrap-price .form-control {
    //   // padding-right: 60px;
    // }

    .wrap-price span.unit {
      right: 13px;
    }

    .form-check {
      padding-left: 24px;
      align-items: start;

      a {
        display: inline-block;
      }

      label {
        font-size: 12px;
        line-height: 18px;
      }

      .form-check-input:not([role="switch"]) {
        margin-top: 2px;
      }
    }

    .wrap-form:last-child:not(:first-child) {
      margin-top: 26px;
    }

    .form-switch .form-check-input {
      width: 43px;
      height: 22px;
    }

    h3.sub {
      margin-bottom: 6px;
    }

    .txt-a.step {
      font-size: 13px;
      margin-bottom: 4px;
      display: block;
    }

    .modal-header {
      padding: 26px 26px 16px 26px;
    }

    .modal-footer {
      padding: 0 26px 28px 26px;
    }

    .modal-content {
      width: 100%;
      max-width: 504px;
      margin: 0 auto;
    }

    &.pop-lg .modal-content {
      min-width: 0;
    }

    &.pop-deposit {
      .wrap-btm {
        margin-top: 0;
      }
    }

    &.pop-step-balance {
      .modal-content {
        min-height: 385px;
      }

      .wrap-box {
        margin-top: 0;
        padding: 8px 0;
      }
    }

    &.pop-step-checkout {
      .modal-body .box.box-line {
        margin: 30px 0;
      }
    }

    &.pop-bid {
      .modal-content {
        min-height: 449px;
      }

      .wrap-form {
        margin-bottom: 16px;
      }
    }

    &.pop-step-convert {
      .modal-body p {
        margin-bottom: 26px;
      }
    }

    .modal-footer {
      .wrap-btn {
        width: 100%;
      }

      .btn {
        font-size: 15px;
        // width: 100%;
        flex: 1 1 auto;
      }
    }
  }

  .pop-calendar.modal .modal-dialog {
    width: 100%;
    margin: 0;

    .modal-content {
      width: 100%;
      max-width: 504px;
      background: #fff;
    }

    .modal-body {
      padding: 26px;

      .wrap-calendar {
        margin: 0 auto;
      }

      .heading {
        font-size: 20px;
      }

      .flatpickr-calendar {
        background: transparent;
        padding: 0;
        margin: 0;
        box-shadow: none;
        max-width: calc(100vw - 52px);
        height: auto;

        .cur-month,
        .cur-year {
          font-size: 16px;
        }

        .flatpickr-months {

          .flatpickr-prev-month,
          .flatpickr-next-month {
            top: 0;
          }
        }

        .flatpickr-weekdays {
          margin-bottom: 5px;
        }

        span.flatpickr-weekday {
          flex: 0 1 auto;
          width: 14.285%;
          font-size: 14px;
        }

        .flatpickr-day {
          font-size: 14px;
          line-height: 36px;
          height: 36px;
        }

        .flatpickr-time {

          input.flatpickr-hour,
          input.flatpickr-minute,
          input.flatpickr-second {
            font-size: 14px;
          }
        }

        .flatpickr-innerContainer {
          margin-bottom: 15px;
        }
      }
    }
  }

  .pop-lg {
    width: 100%;
  }
}