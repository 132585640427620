.accordion-body {
  padding: 10px 18.5px 22px 18.5px;

  p.intro {
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 0;
    color: #030229;
  }

  .wrap-btn-filter {
    margin: -10px 0 25px 0;
  }
}

// accordion
.accordion {
  display: flex;
  flex-direction: column;

  // * {
  //   // transition: .3s;
  // }
}

.accordion-item {
  background: linear-gradient(180deg, #fefeff -16%, #f4f5f8 357.67%);
  // background: transparent;
  // box-shadow: 0px 8px 8px rgba(24, 39, 75, 0.04), 0px 4px 6px rgba(24, 39, 75, 0.08);
  border-radius: 18px;
  border: 1px solid #f0f2f4;
  margin-bottom: 15px;
  overflow: hidden;
  isolation: isolate;

  &:not(:first-of-type) {
    border: 1px solid #f0f2f4;
  }

  .accordion-body {
    overflow: auto;
  }
}

.accordion-item:first-of-type,
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
}

.accordion-item:last-of-type,
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 18px;
  border-bottom-left-radius: 18px;
}

.accordion-button {
  background-color: transparent;
  font-weight: 600;
  font-size: 16px;
}

.accordion-button:not(.collapsed) {
  color: #030229;
  background-color: transparent;
  box-shadow: none;
}

.accordion-button::after {
  // background-image: url(../../img/i_arr_round.svg);
  background-size: 100% 100%;
  width: 18px;
  height: 18px;
}

.accordion-button:not(.collapsed)::after {
  background-image: url(../../img/i_arr_round.svg);
  background-size: 100% 100%;
  transform: rotate(-180deg);
  width: 18px;
  height: 18px;
}
:root {
  .accordion-button::after {
    background-image: url(../img/i_arr_round_light.svg); 
  }
  .accordion-button-table::after {
    background-image: url(../img/i_arr_md_lightmode.svg);
  }
}
[data-theme='dark'] {
  .accordion-button::after {
    background-image: url(../img/i_arr_md_darkmode.svg);
  }
  .accordion-button-table::after {
    background-image: url(../img/i_arr_round_dark.svg);
  }
}

main .acc-table {
  .thead,
  .tbody .tr {
    display: flex;
    gap: 14px;
    align-items: center;
    justify-content: start;

    &.open {
      align-items: start;
    }

    .th,
    .td {
      flex: 0 0 auto;
      color: var(--text-form);
    }
  }

  .thead {
    padding: 10px 0 22px 0;

    .th {
      font-weight: 500;
    }
  }

  .tr {
    display: flex;
  }

  .accordion {
    width: 100%;
  }

  .accordion-button {
    width: auto;
    margin: 0;
  }

  .accordion-button::after {
    // background-image: url(../img/i_arr_md.svg);
    // background-size: 40px auto;
    width: 24px;
    height: 24px;
    background-position: center center;
  }

  .accordion-item {
    border: 0;
    background: transparent;
    border-top: 1px solid #c8cacf;
    margin: 0;
    border-radius: 0 !important;
  }

  button.accordion-button {
    margin-left: auto;
  }

  .btn-grad {
    &.disabled {
      opacity: 1;
      color: #bac2cf;
      background: #f7f9fc;
    }
  }

  & + .box-btm {
    background: var(--bg-box-add-launchtool);
    border-radius: 5px;
    padding: 13px;
    font-weight: 700;
    text-align: center;
    margin-top: 10px;
    color: var(--text-form);
  }
}

// @media (max-width: 991.98px)
@include media-breakpoint-down(md) {
  .accordion.li-profile {
    border-radius: 18px;

    .accordion-header {
      display: flex;
    }

    .accordion-button {
      padding: 16px 17px;
      width: auto;
    }

    .link.item {
      padding: 16px 0 16px 17px;
      flex: 1 0 auto;
      max-width: calc(100% - 60px);
      display: flex;
      align-items: center;

      .thumb {
        width: 54px;
        height: 54px;
        border-radius: 8px;
        margin-right: 12px;
      }

      .txt {
        overflow: hidden;

        h6 {
          font-size: 14px;
          font-weight: 400;
          margin-bottom: 5px;
          @include t-ell;
        }

        h5 {
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 0;
          @include t-ell;
        }
      }
    }

    .accordion-body {
      padding: 15px 17px;
      background: #fff;
      gap: 15px;

      .tr {
        justify-content: space-between;
        align-items: start;

        .wrap-btn {
          text-align: center;
          align-items: center;

          .btn {
            font-size: 14px;
            font-weight: 500;
            height: 29px;
            min-width: 95px;
            margin: 0 auto;
          }
        }
      }

      .label {
        font-weight: 500;
        font-size: 12px;
        color: #464f60;
      }

      .value {
        text-align: right;
        flex: 0 0 auto;

        h5 {
          font-weight: 400;
          font-size: 14px;
          color: #030229;
          margin-bottom: 0;
        }

        .action {
          img {
            margin-right: 5px;
            height: 14px;
          }
        }

        .link-a > h5 {
          color: #fc6825;
        }

        .price {
          flex-direction: column;
          text-align: right;

          .klay:before {
            width: 14px;
            height: 14px;
          }

          h5 {
            font-weight: 400;
            font-size: 14px;
            color: #030229;
            margin-bottom: 2px;
          }

          h6 {
            font-weight: 400;
            font-size: 12px;
            color: #6f6f6f;
            margin-bottom: 0;
          }
        }
      }
    }

    .accordion-header {
      background: #f6f9fb;
    }

    .accordion-button::after {
      background-image: url(../img/i_arr_round_g.svg);
      width: 22px;
      height: 22px;
    }

    .accordion-item {
      border: 2px solid #f6f9fb;
      box-shadow: none;
    }

    .accordion-item:last-of-type,
    .accordion-item:last-of-type .accordion-collapse {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      margin-bottom: 0;
      box-shadow: none;
    }

    .td a.link,
    .td .item {
      .txt {
        flex: 1 0 auto;
      }
    }
  }
}
