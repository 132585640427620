:root {
  --bs-breadcrumb-divider: ">"
}

.tooltip.bs-tooltip-top {
  margin-bottom: 4px;
}

.tooltip-inner {
  background: #f7f9fc;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #464f60;
}

.tooltip.bs-tooltip-right .tooltip-arrow:before {
  border-right-color: #f7f9fc;
}

.tooltip.bs-tooltip-left .tooltip-arrow:before {
  border-left-color: #f7f9fc;
}

.tooltip.bs-tooltip-bottom .tooltip-arrow:before {
  border-bottom-color: #f7f9fc;
}

.tooltip.bs-tooltip-top .tooltip-arrow:before {
  border-top-color: #f7f9fc;
}

.modalAddToken .tooltip-inner {
  color: white;
  background: #464f60;
}

.modalAddToken.bs-tooltip-right .tooltip-arrow:before {
  border-right-color: #464F60;
}

.modalAddToken.bs-tooltip-left .tooltip-arrow:before {
  border-left-color: #464F60;
}

.modalAddToken.bs-tooltip-bottom .tooltip-arrow:before {
  border-bottom-color: #464F60;
}

.modalAddToken.bs-tooltip-top .tooltip-arrow:before {
  border-top-color: #464F60;
}

.modalAddToken.bs-tooltip-top {
  margin-bottom: 0px;
}

.admin-side .wrap-menu a {
  color: #697a8d;
}

.border-radius {
  height: 30px;
  border: 6px solid #ededef;
  border-radius: 100px;
  top: auto;
}

.wrap-dot {
  position: absolute;
  top: 60px;
  right: -2px;
  bottom: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  height: 0px;

  .custom-style-dot {
    height: 0px !important;
    background-color: #ff2f00 !important;
  }

  .dot {
    cursor: pointer;
    position: relative;
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 100px;
    background-color: white;
  }

  .dot.six {
    right: 8px;
  }

  .dot.seven {
    right: 16px;
  }

  .dot.eight {
    right: 22px;
  }

  .dot.one {
    right: -28px;
  }

  .dot.two {
    right: -20px;
  }

  .dot.three {
    right: -14px;
  }

  .dot.four {
    right: -6px;
  }

  .dot.nine {
    right: 29.5px;
  }
}

.fw-500 {
  font-weight: 500;
}

form {
  .wrap-form {
    .count {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: right;
      color: #464f60;
      margin-top: 15px;
    }

  }
}

.sticky-table {
  .table> :not(caption)>*>* {
    padding: none;
  }
}

.container-admin aside.admin-side {
  // max-height: calc(100vh - 72px);
  max-height: 100vh;
  overflow-y: auto;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
  }


  // &::-webkit-scrollbar-track {
  //   box-shadow: inset 0 0 5px grey;
  // }

  // &::-webkit-scrollbar-thumb {
  //   background: red;
  //   // border-radius: 10px;
  // }
}

.color-blue {
  color: #1890ff !important;
}

.gnb {
  z-index: 100;
}
// custom pagination
.css-1v2lvtn-MuiPaginationItem-root {
  color: var(--text-primary) !important;
}

.css-g2z002-MuiSvgIcon-root-MuiPaginationItem-icon {
  fill: var(--text-primary) !important;
}
.css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root {
  border: 1px solid var(--c-border-line-04) !important;
  color: var(--text-primary) !important;
  border-radius: 6px;
  background: #1B1B35 !important;
}
.css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: var(--bg-box-companies-page) !important;
  border: 1px solid #2f80ed !important;
  color: #FFFFFF !important;
}

